import * as R from 'ramda';
import React, { useState } from 'react';
import { Button, Form, Icon, Input, Select, Tooltip } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../../Environment';
import Loading from '../../../../components/helpers/Loading';
import { passwordIsValid } from '../../../../lib/validation';
import AccountUpdateMutation from '../../../../mutations/AccountUpdateMutation';
import { map } from 'ramda';
import { salesList } from '../../../../data/salesList';

const { Option } = Select;

const AccountUpdateQuery = graphql`
query AccountUpdateQuery {
  data {
    managementCenters {
      name
      code
    }
    regions {
      code
      title
    }
  }
}
`;

const UpdateForm = ({
  form,
  user
}) => {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const updateUser = e => {
    e.preventDefault();
    form.validateFields((errors, values) => {
      if (R.isNil(errors)) {
        setLoading(true);
        setMessage('');

        values = R.modify(
          'sales',
          R.when(
            R.isNil,
            R.always(null)
          )
        )(values);

        AccountUpdateMutation({ id: user.id, values }, (ok, error) => {
          if (error) setMessage(`Une erreur est survenue [${error}].`);
          if (ok) setMessage('L\'utilisateur a été mis à jour.');
          setLoading(false);
        });
      } else {
        setMessage('Vérifiez les données saisies.');
      }
    });
  };

  const { getFieldDecorator } = form;

  const get = (prop, or = '') => R.pathOr(or, prop.split('.'))(user);
  const userRole = () => {
    const roles = R.propOr([], 'roles')(user);
    if (R.includes('groupe_adhesion')(roles)) return 'groupe_adhesion';
    if (R.includes('reseau_commercial')(roles)) return 'reseau_commercial';
    if (R.includes('apporteur')(roles)) return 'apporteur';
    return null;
  };

  const userIs = role => form.getFieldValue('role') === role;

  const validatePassword = (rule, password, callback) => {
    if (R.isEmpty(password) || (password && passwordIsValid(password, [
      form.getFieldValue('firstname'),
      form.getFieldValue('lastname'),
      form.getFieldValue('email')
    ]))) callback();
    else callback('Le mot de passe est invalide.');
  };

  return <QueryRenderer
    environment={environment}
    query={AccountUpdateQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error}</div>;
      } else if (!props) return <Loading />;

      const managementCenters = R.pathOr([], ['data', 'managementCenters'])(props);
      const regions = R.pathOr([], ['data', 'regions'])(props);

      if (R.length(managementCenters) === 0 || R.length(regions) === 0) return null;

      return <Form
        labelCol={{ xs: { span: 6 }, sm: { span: 6 } }}
        wrapperCol={{ xs: { span: 10 }, sm: { span: 10 } }}
        onSubmit={updateUser}>

        <Form.Item label="Email" hasFeedback>
          {getFieldDecorator('email', {
            rules: [{
              type: 'email',
              message: 'L\'adresse entrée n\'est pas valide.'
            },
            {
              required: true,
              message: 'Renseignez une adresse mail.'
            }],
            initialValue: get('email')
          })(<Input />)}
        </Form.Item>

        <Form.Item
          label={<span>
            Mot de passe&nbsp;
            <Tooltip title="Le mot de passe doit contenir au moins 8 caractères (dont au moins un de type lettre, un de type chiffre et un de type symbole). Il ne peut pas contenir le prénom, nom ou adresse mail de l'utilisateur.">
              <Icon type="question-circle-o" />
            </Tooltip>
          </span>}
          hasFeedback
        >
          {getFieldDecorator('password', {
            rules: [{
              validator: validatePassword
            }],
            initialValue: ''
          })(<Input.Password />)}
        </Form.Item>

        <Form.Item label="Nom" hasFeedback>
          {getFieldDecorator('lastname', {
            rules: [{
              required: true,
              message: 'Renseigner un nom.'
            }],
            initialValue: get('extra.lastname')
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Prénom" hasFeedback>
          {getFieldDecorator('firstname', {
            rules: [{
              required: true,
              message: 'Renseignez un prénom.'
            }],
            initialValue: get('extra.firstname')
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Rôle" hasFeedback>
          {getFieldDecorator('role', {
            rules: [{
              required: true,
              message: 'Choisissez un rôle.'
            }],
            initialValue: userRole()
          })(
            <Select>
              <Option value="reseau_commercial">Réseau commercial</Option>
              <Option value="groupe_adhesion">Groupe adhésion</Option>
              <Option value="apporteur">Apporteur</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Code commercial" hasFeedback>
          {getFieldDecorator('userCode', {
            initialValue: get('extra.userCode')
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Centre de gestion" hasFeedback>
          {getFieldDecorator('managementCenter', {
            rules: [{
              required: userIs('groupe_adhesion'),
              message: 'Choisissez un centre de gestion.'
            }],
            initialValue: get('extra.managementCenter')
          })(
            <Select allowClear={userIs('reseau_commercial')}>
              {R.map(managementCenter => <Option key={managementCenter.code} value={managementCenter.code}>{managementCenter.name}</Option>)(managementCenters)}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Région" hasFeedback>
          {getFieldDecorator('region', {
            rules: [{
              required: !userIs('apporteur'),
              message: 'Choisissez une région.'
            }],
            initialValue: get('extra.region')
          })(
            <Select>
              {R.map(region => <Option key={region.code} value={region.code}>{region.title}</Option>)(regions)}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Structure commerciale" hasFeedback>
          {getFieldDecorator('sales', {
            rules: [{
              required: userIs('reseau_commercial'),
              message: 'Choissisez une structure commerciale.'
            }],
            initialValue: get('extra.sales')
          })(
            <Select
              allowClear
            >
              {map(({ label, value }) => {
                return <Option
                  key={`sales-${value}`}
                  value={value}
                >
                  {label}
                </Option>;
              })(salesList)}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Type d'apporteur" hasFeedback>
          {getFieldDecorator('providers', {
            rules: [{
              required: userIs('apporteur'),
              message: 'Sélectionnez les apporteurs'
            }],
            initialValue: get('extra.providers', [])
          })(
            <Select
              mode="multiple"
            >
              <Option value="UGM UMANENS">UGM UMANENS</Option>
              <Option value="LA MUTUELLE FAMILIALE">LA MUTUELLE FAMILIALE</Option>
              <Option value="IDENTITES MUTUELLE">IDENTITES MUTUELLE</Option>
              <Option value="MUTUALIA ALLIANCE SANTE">MUTUALIA ALLIANCE SANTE</Option>
              <Option value="MUTUALIA GRAND OUEST">MUTUALIA GRAND OUEST</Option>
              <Option value="MUTUALIA TERRITOIRES SOLIDAIRES">MUTUALIA TERRITOIRES SOLIDAIRES</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Email apporteur" hasFeedback>
          {getFieldDecorator('providerEmail', {
            rules: [{
              type: 'email',
              message: 'L\'adresse entrée n\'est pas valide.'
            }, {
              required: userIs('apporteur'),
              message: 'Saisissez l\'adresse mail apporteur'
            }],
            initialValue: get('extra.providerEmail')
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item wrapperCol={{ xs: { span: 10, offset: 6 }, sm: { span: 10, offset: 6 } }}>
          <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
            {'Mettre à jour l\'utilisateur'}
          </Button>
          <span style={{ marginLeft: '10px' }}>{message}</span>
        </Form.Item>
      </Form>;

    }}
  />;
};

const AccountUpdate = Form.create({ name: 'update' })(UpdateForm);

export default AccountUpdate;
